import React, { useState } from 'react'
import * as st from '~/assets/styl/RatingButtons.module.styl'
import { rating } from '~/data'
import { useContext } from '~/hooks'
import useApi from '~/siteApi'
import cn from 'classnames'
import { useSnackbar } from 'react-simple-snackbar'

export default function RatingButtons({ id }: { id: number }) {
  const { token, profile, setProfile } = useContext()
  const [active, setActive] = useState(false)

  const options = {
    position: 'bottom-right',
    style: {
      backgroundColor: '#ff8609',
      textAlign: 'center',
      fontSize: '16px',
    },
    closeStyle: {
      color: 'white',
      fontSize: '16px',
    },
  }

  const [openSnackbar, closeSnackbar] = useSnackbar(options)

  function handleRating(e, rating) {
    e.preventDefault()
    const { account_steps } = profile
    useApi(
      'patch',
      `steps/${id}/rating`,
      { rating },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        openSnackbar('Seu voto foi computado, obrigado!')
        setTimeout(() => {
          closeSnackbar()
          const step = account_steps.find(({ step }) => step === id)
          if (!step) account_steps.push({ step: id, rating, finished: false })
          else step.rating = rating
          setProfile(profile)
        }, 3000)
      })
      .catch(({ errors }) => {
        console.error(errors)
      })
  }

  return (
    <div className={st.ratingWrapper}>
      <>
        <p>O que você achou desse conteúdo</p>
        <div className={st.ratingContainer}>
          <ul>
            {rating.map((item, index) => (
              <li key={index}>
                <button onClick={(e) => handleRating(e, item.value)}>
                  <span className={item.icon}></span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </>
    </div>
  )
}
