import React from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import GoBackButton from './GoBackButton'
import * as st from '~/assets/styl/StepDetail.module.styl'
import RatingButtons from './RatingButtons'
import { useContext } from '~/hooks'
import api from '~/siteApi'
import { LazyImage } from 'react-lazy-images'

type Props = {
  pageContext: {
    id: number
    image: string
    cover: string
    rating: number
    name: string
    description: string
    video: string
    video_code: string
    previous: {
      id: number
      name: string
      cover: string
      description: string
    }
    next: {
      id: number
      name: string
      cover: string
      description: string
    }
  }
}

export default function StepDetail({
  pageContext: {
    id,
    name,
    image,
    cover,
    description,
    video,
    previous,
    next,
    rating,
    video_code,
  },
}: Props) {
  const { profile, token, setProfile } = useContext()
  const isLogged = Boolean(profile)

  const embedVideo = `https://www.youtube.com/embed/${video_code}`

  const complete = (profile?.account_steps || []).map((comp) => comp.step)
  const rated = (profile?.account_steps || [])
    .filter(({ rating }) => rating || rating === 0)
    .map(({ step }) => step)

  function completed(e, id) {
    e.preventDefault()
    const { account_steps } = profile
    api('patch', `steps/${id}/finish`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        account_steps.push({ step: id })
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  return (
    <div className={cn(st.stepContainer, 'page-width')}>
      <div className={st.backAndComplete}>
        <GoBackButton />
        {isLogged && (
          <>
            {complete.includes(id) ? (
              <div className={st.complete}>
                <span className="icon-done"></span>
                Passo concluído
              </div>
            ) : (
              <div onClick={(e) => completed(e, id)} className={st.toComplete}>
                <span className="icon-done"></span>
                Concluir passo
              </div>
            )}
          </>
        )}
      </div>
      {video_code && (
        <div className={st.videoContainer}>
          <iframe
            src={embedVideo}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div>
      )}
      <section className={st.stepWrapper}>
        <h1>{name}</h1>
        {image && (
          <div className={st.stepImage}>
            <LazyImage
              src={image}
              alt={name}
              placeholder={({ imageProps, ref }) => (
                <img ref={ref} src={image} alt={name} />
              )}
              actual={({ imageProps }) => <img {...imageProps} />}
            />
          </div>
        )}
        <p>{description}</p>
      </section>
      {isLogged && !rated.includes(id) && <RatingButtons id={id} />}
      <div className={st.prevNext}>
        {previous && (
          <div className={st.previous}>
            <Link to={'/passo/' + previous.id}>
              <div
                className={st.prevImage}
                style={{
                  backgroundImage: `url(${previous.cover})`,
                }}
              >
                {isLogged && (
                  <div className={st.markers}>
                    {(complete.includes(previous.id) && (
                      <span className={cn(st.stepDone, 'icon-done')}></span>
                    )) || (
                      <span
                        className={cn(st.stepUndone, 'icon-disclaimer')}
                      ></span>
                    )}
                  </div>
                )}
              </div>
              <div className={st.prevDesc}>
                <h2>{previous.name}</h2>
                <p>{previous.description}</p>
                <div className={st.prevButton}>
                  <span className="icon-arrow"></span>
                  Anterior
                </div>
              </div>
            </Link>
          </div>
        )}
        {next && (
          <div className={st.next}>
            <Link to={'/passo/' + next.id}>
              <div
                className={st.nextImage}
                style={{
                  backgroundImage: `url(${next.cover})`,
                }}
              >
                {isLogged && (
                  <div className={st.markers}>
                    {(complete.includes(next.id) && (
                      <span className={cn(st.stepDone, 'icon-done')}></span>
                    )) || (
                      <span
                        className={cn(st.stepUndone, 'icon-disclaimer')}
                      ></span>
                    )}
                  </div>
                )}
              </div>
              <div className={st.nextDesc}>
                <h2>{next.name}</h2>
                <p>{next.description}</p>
                <div className={st.nextButton}>
                  <span className="icon-arrow"></span>
                  Próximo
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
